<template>
  <b-dialog v-model="visible" title="专项重点工作名称" width="600px">
    <el-form ref="form" :model="formData" :rules="rules" label-position="top">
      <el-form-item label="标题" prop="title">
        <el-input v-model="formData.title"  placeholder="请输入标题"></el-input>
      </el-form-item>
      <el-form-item label="关联板块" prop="parent_id">
         <el-select v-model="formData.parent_id" placeholder="请选择板块">
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.title"
              :value="item.id">
            </el-option>
          </el-select>
      </el-form-item>
      <el-form-item label="内容" prop="content">
        <!-- <EditorTextbus
          v-model="formData.content"
          :autosize="{ minRows: 3 }"
          @input="editConent()"

          placeholder="请输入内容"
        ></EditorTextbus> -->
        <el-input
          type="textarea"
          :rows="3"
          placeholder="请输入内容"
          v-model="formData.content">
        </el-input>

      </el-form-item>


        <el-form-item label="附件">
          <c-upload-media
            v-model="formData.media_keys"
            :default="defaultMedia"
          ></c-upload-media>
        </el-form-item>

      <el-form-item label="指定关注人" prop="users">
        <b-select-user v-model="formData.users" multiple scope="all"></b-select-user>
      </el-form-item>
      <el-form-item label="指定完成部门" prop="departments">
        <b-select-dept v-model="formData.departments" multiple></b-select-dept>
      </el-form-item>
      <div style="text-align: right;">
        <b-btn-confirm :loading="submitLoading" @click="onSubmit"></b-btn-confirm>
      </div>
    </el-form>
  </b-dialog>
</template>

<script>
import { createSpecial, updateSpecial,getSpecialPage } from '@/api/special'
import EditorTextbus from '@/components/editor/textbus'
import BSelectDept from '@/components/select/cascader/dept'
import CUploadMedia from '@/components/upload/media'
import BSelectUser from '@/components/select/cascader/user'
export default {
  components: {
    BSelectDept,
    BSelectUser,
    EditorTextbus,
    CUploadMedia
  },
  data() {
    return {
      visible: false,
      defaultMedia: [],
      formData: {
        title: '',
        content: '',
        end_date: '',
        parent_id:"",
        departments: [],
        departments: [],
        users: [],
        media_keys: [],
      },
      options: [],
      value:"",
      rules: {
        title: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        parent_id: [{ required: true, message: '请选择板块', trigger: 'blur' }],
        content: [{ required: true, message: '请输入内容', trigger: 'blur' }],
      },
      submitLoading: false,
    }
  },
    mounted() {
      this.getSpecial();
    },
  methods: {
    getSpecial(){
        getSpecialPage({
          parent_id:248
        }) .then(res => {
            this.options=res.data
          })
          .finally(() => {
             this.$emit('获取板块列表失败，请稍后再试')
          })
    },
    open(val) {

      this.formData = val
      this.visible = true
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
    },
    onSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          const params = {
            title: this.formData.title,
            content: this.formData.content,
            media_keys:this.formData.media_keys,
          }
          if (this.formData.departments.length) {
            params.departments = this.formData.departments
          }
          if (this.formData.users.length) {
            params.users = this.formData.users
          }
          if (this.formData.parent_id) {
            params.parent_id = this.formData.parent_id
          }

          if (this.formData.id) {
            updateSpecial(this.formData.id, params)
              .then(res => {
                this.$message.success('修改成功')
                this.$emit('success')
                this.visible = false
              })
              .finally(() => {
                this.submitLoading = false
              })
          } else {
            createSpecial(params)
              .then(res => {
                this.$message.success('新建成功')
                this.$emit('success')
                this.visible = false
              })
              .finally(() => {
                this.submitLoading = false
              })
          }
        }
      })
    },
  },
}
</script>
